import request from '@/utils/request'


export const designerTeamList: any = (data: any) => {
    console.log(data)
    return request({
        url: '/designer/designerTeamList',
        method: 'post',
        data
    })
}

export const designerTeam: any = (data: any) => {
    console.log(data)
    return request({
        url: '/designer/designerTeam',
        method: 'post',
        data
    })
}

export const getMembersById: any = (id: string) => {
    return request({
        url: `/designer/teamMembers/${id}`,
        method: 'get',
    })
}

export const pageMembers: any = (data: any) => {
    return request({
        url: `/designer/pageListDesigners`,
        method: 'post',
        data
    })
}

export const getById: any = (id: string) => {
    return request({
        url: `/designer/team/${id}`,
        method: 'get',
    })
}

export const insertDesigner: any = (data: any) => {
    return request({
        url: `/designer/`,
        method: 'post',
        data
    })
}
export const updateDesigner: any = (data: any) => {
    return request({
        url: `/designer/`,
        method: 'put',
        data
    })
}

export const getDesignerById: any = (id: string,teamId: string) => {
    return request({
        url: `/designer/${id}/${teamId}`,
        method: 'get',
    })
}

export const addAdvise: any = (data: any) => {
    return request({
        url: `/patient/case/order/expert/advise`,
        method: 'post',
        data
    })
}

export const getAdvise: any = (orderId: string) => {
    return request({
        url: `/patient/case/order/expert/advise/${orderId}`,
        method: 'get',
    })
}
export const getListDentist: any = (data: any ) => {
    return request({
        url: `/dentist/group/listDentist`,
        method: 'post',
        data
    })
}
export const saveBoundings: any = (data: any ,type:string) => {
    return request({
        url: `/dentist/group/saveBounding/${type}`,
        method: 'post',
        data
    })
}